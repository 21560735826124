import { Badge, CircularProgress, Stack, SxProps } from "@mui/material";
import { useAppSelector } from "../../Redux/hooks";
import { QuestionSlice } from "../../Redux/reducers/responseSlice";

export const responseIndexStyle: SxProps = {
  height: "50px",
  width: "50px",
  bgcolor: "primary.main",
  color: "#FFF",
  alignItems: "center",
  justifyContent: "center",
  flexShrink: 0,

  borderRadius: 0.5,
};

type Props = {
  question: QuestionSlice;
  index: number;
};

const QuestionItemIndex: React.FC<Props> = ({ question, index }) => {
  const handleBadgeColor = () => {
    if (
      question?.responses?.length > 0 &&
      !question.responses?.[0]?.notSaved &&
      !question.responses?.[0]?.isCleared
    ) {
      return "#31C48D";
    } else {
      return "#FACA15";
    }
  };

  const isResponseSubmitting = useAppSelector(
    (state) => state.response.fieldSubmitting
  );

  return ["statement"].includes(question?.type) ? (
    ["statement"].includes(question?.type) &&
    !question?.properties?.showIndex ? (
      <></>
    ) : (
      <Stack sx={responseIndexStyle}>
        {question?.properties?.displayNumber
          ? question?.properties?.displayNumber
          : index + 1}
      </Stack>
    )
  ) : isResponseSubmitting === question.id ? (
    <Stack sx={responseIndexStyle}>
      <CircularProgress color="inherit" size={18} />
    </Stack>
  ) : (
    <Badge
      sx={{
        "& .MuiBadge-badge": {
          backgroundColor: handleBadgeColor(),
          fontSize: "40px",
        },
      }}
      variant="dot"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
    >
      <Stack sx={responseIndexStyle}>
        {question?.properties?.displayNumber
          ? question?.properties?.displayNumber
          : index + 1}
      </Stack>
    </Badge>
  );
};

export default QuestionItemIndex;
