import { useState } from "react";
import {
  Box,
  Step,
  StepLabel,
  Stepper,
  SxProps,
  Typography,
} from "@mui/material";
import GeneralAndSiteInfo from "./Steps/GeneralAndSiteInfo";
import Features from "./Steps/Features";
import Settings from "./Steps/Settings";
import { CommonDrawerStyle, DrawerHeader } from "../Common/styles/drawer";

type Props = {
  closeDrawer: () => void;
  refreshPage: () => void;
};

const CustomStepWrapper: SxProps = {
  mb: 3,
  "& .MuiStepConnector-root": {
    display: "none",
  },
  "& .MuiStep-root": {
    width: "50%",
  },
};

const CustomStepStyle: SxProps = {
  "& .MuiSvgIcon-root.Mui-completed": {
    color: "#70AE71",
  },
  "& .MuiSvgIcon-root.Mui-active": {
    color: "primary.main",
  },
  "& .MuiStepIcon-root": {
    height: "34px",
    width: "34px",
  },
  "& .MuiStepLabel-label": {
    fontSize: "18px",
    fontWeight: "500",
  },
};

const studySteps = ["General and Center Information", "Features", "Settings"];

const AddStudyDetailDrawer = ({ closeDrawer, refreshPage }: Props) => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const [studyId, setStudyId] = useState<string>("");

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  return (
    <Box sx={CommonDrawerStyle}>
      <DrawerHeader title="Add a New Program" closeDrawer={closeDrawer} />
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Stepper activeStep={activeStep} sx={CustomStepWrapper}>
          {studySteps.map((label, index) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};
            return (
              <Step key={label} {...stepProps} sx={CustomStepStyle}>
                <StepLabel {...labelProps}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      fontWeight={400}
                      fontSize={16}
                      color="text.secondary"
                    >
                      Step {index + 1}
                    </Typography>
                    <Typography
                      fontWeight={500}
                      fontSize={18}
                      color="text.primary"
                    >
                      {label}
                    </Typography>
                  </Box>
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <Box
          sx={{
            px: activeStep === 2 ? 0 : 2.5,
            overflow: "auto",
            height: "calc(100vh - 188px)",
          }}
        >
          {activeStep === 0 && (
            <GeneralAndSiteInfo
              handleNext={handleNext}
              setStudyId={setStudyId}
            />
          )}
          {activeStep === 1 && (
            <Features
              handleBack={handleBack}
              studyId={studyId}
              refreshPage={refreshPage}
              closeDrawer={closeDrawer}
              handleNext={handleNext}
            />
          )}
          {activeStep === 2 && (
            <Settings
              refreshPage={refreshPage}
              closeDrawer={closeDrawer}
              studyId={studyId}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default AddStudyDetailDrawer;
