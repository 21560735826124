import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface HealthState {
  type: string;
  menuType: string;
  startDate: string | null;
  endDate: string | null;
  loading: boolean;
  userData: any;
  toggleReload: boolean;
  dateLoaded: boolean;
  selectedDate: number;
  disableButton: boolean;
  criteria: any;

  urlLoaded: boolean;
}

const initialState: HealthState = {
  type: "overview",
  menuType: "patient_profile",
  startDate: null,
  endDate: null,
  loading: true,
  userData: null,
  toggleReload: false,
  dateLoaded: true,
  selectedDate: 7,
  disableButton: false,
  criteria: "day",

  urlLoaded: false,
};

const getIntialState = (): HealthState => {
  const type = sessionStorage?.getItem("response-view") ?? "overview";
  const menuType =
    sessionStorage?.getItem("response-view-main-menu") ?? "patient_profile";
  return {
    ...initialState,
    type,
    menuType,
  };
};

export const healthSlice = createSlice({
  name: "health",
  initialState: getIntialState,
  reducers: {
    setHealthData: (
      state,
      action: PayloadAction<{
        start: string;
        end: string;
      }>
    ) => {
      state.startDate = action.payload.start;
      state.endDate = action.payload.end;
      state.loading = false;
    },
    setHealthUser: (state, action: PayloadAction<any>) => {
      state.userData = action.payload;
    },
    setHealthLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setHealthType: (state, action: PayloadAction<string>) => {
      state.type = action.payload;
    },
    setHealthMenuType: (state, action: PayloadAction<string>) => {
      state.menuType = action.payload;
    },
    setHealthStartDate: (state, action: PayloadAction<string | null>) => {
      state.startDate = action.payload;
    },
    setHealthEndDate: (state, action: PayloadAction<string | null>) => {
      state.endDate = action.payload;
    },
    setToggle: (state, action: PayloadAction<boolean>) => {
      state.toggleReload = action.payload;
    },
    setDateLoaded: (state, action: PayloadAction<boolean>) => {
      state.dateLoaded = action.payload;
    },
    setSelectedDate: (state, action: PayloadAction<number>) => {
      state.selectedDate = action.payload;
    },
    setButtonToggle: (state, action: PayloadAction<boolean>) => {
      state.disableButton = action.payload;
    },
    setHealthDefaults: (state, action: PayloadAction<any>) => {
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
      state.urlLoaded = true;
    },
    reset: () => initialState,
  },
});

export const {
  setHealthType,
  setHealthMenuType,
  reset,
  setHealthEndDate,
  setHealthStartDate,
  setHealthData,
  setHealthLoading,
  setHealthUser,
  setToggle,
  setDateLoaded,
  setSelectedDate,
  setButtonToggle,
  setHealthDefaults,
} = healthSlice.actions;

export default healthSlice.reducer;
