import NumberField from "./PrintItems/NumberField";
import DateField from "./PrintItems/DateField";
import RadioField from "./PrintItems/RadioField";
import SliderField from "./PrintItems/SliderField";
import TextField from "./PrintItems/TextField";
import GridField from "./PrintItems/GridField";
import { FileUploadField } from "./PrintItems/OtherTypes";
import RepeatedField from "./PrintItems/RepeatedField";

export const PrintcomponentMap: Record<string, any> = {
  text: TextField,
  date: DateField,
  radio: RadioField,
  dropdown: RadioField,
  checkbox: RadioField,
  number: NumberField,
  slider: SliderField,
  grid: GridField,
  repeated_data: RepeatedField,
  upload_file: FileUploadField,
  rank_order: RadioField,
};
