import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import { ModalDrawerButtonStyles } from "../../Common/styles/modal";
import { DrawerForm } from "../../Common/styles/drawer";
import http from "../../../utils/http";
import { AxiosResponse } from "axios";

type Props = {
  closeDrawer: () => void;
  refreshPage: () => void;
  studyId: string;
};

const SettingCheckbox: React.FC<{
  label: string;
  checked: boolean;
  onChange: () => void;
}> = ({ label, checked, onChange }) => (
  <>
    <FormControlLabel
      componentsProps={{
        typography: { sx: { fontSize: 16 } },
      }}
      sx={{ fontSize: 16, fontWeight: 500 }}
      control={<Checkbox checked={checked} onChange={onChange} />}
      label={label}
    />
    <Divider />
  </>
);

const Settings: React.FC<Props> = ({ closeDrawer, refreshPage, studyId }) => {
  const navigation = useNavigate();
  const [submitLoader, setSubmitLoader] = useState(false);
  const [settings, setSettings] = useState<{
    twoFactorAuth: boolean;
    selfRegister: boolean;
  }>({
    twoFactorAuth: false,
    selfRegister: false,
  });

  const handleCheckboxChange = (key: "twoFactorAuth" | "selfRegister") => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      [key]: !prevSettings[key],
    }));
  };

  const handleSubmit = async () => {
    try {
      setSubmitLoader(true);
      let obj: any = {
        isMfaEnabled: settings.twoFactorAuth,
        isParticipantSelfRegistrationEnabled: settings.selfRegister,
      };
      const res: AxiosResponse = await http.put(
        `/study/${studyId}/settings/general`,
        obj
      );
      toastMessage("success", res.data.message);
      closeDrawer();
      refreshPage();
      navigation(`/studies/${studyId}/participants`);
    } catch (err) {
      errorToastMessage(err as Error);
    } finally {
      setSubmitLoader(false);
    }
  };

  return (
    <Box sx={DrawerForm}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2, p: 2.5 }}>
        <Typography
          fontSize="16px"
          fontWeight={400}
          color="text.secondary"
          mb={2}
        >
          Configure options like Two-Factor Authentication (2FA) for security
          and allow patients to self-register, tailoring the program setup to
          your needs.
        </Typography>
        <Divider />
        <SettingCheckbox
          label="Allow Two-Factor Authentication (2FA)"
          checked={settings.twoFactorAuth}
          onChange={() => handleCheckboxChange("twoFactorAuth")}
        />
        <SettingCheckbox
          label="Allow patient to self-register for the program"
          checked={settings.selfRegister}
          onChange={() => handleCheckboxChange("selfRegister")}
        />
      </Box>
      <Box sx={{ marginTop: "auto" }}>
        <Divider />
        <Box sx={ModalDrawerButtonStyles}>
          {!submitLoader ? (
            <Button variant="contained" onClick={handleSubmit}>
              Submit
            </Button>
          ) : (
            <CircularProgress size={25} />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Settings;
