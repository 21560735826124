import axios from "axios";
import http from "./http";
import { SignedUrlRoot } from "../types/SignedUrl.types";

export const uploadFile = async (file: any, type: string) => {
  const fileObj = {
    assets: [{ fileName: file.name, type }],
  };
  const res = await http.post("/assets/get_upload_urls", fileObj);
  await axios.put(res.data.data?.[0]?.presignedUploadUrl, file, {
    headers: { "Content-Type": res.data.data?.[0]?.mimeType },
  });
  return res.data.data?.[0]?.postUploadImageUrl;
};

export const educationModuleUploadFile = async (
  file: any,
  studyId: string | undefined
) => {
  const fileObj = {
    fileName: file.name,
  };
  const res = await http.post(
    `/admin/study/${studyId}/lms/lessons/get_upload_url`,
    fileObj
  );
  await axios.put(res.data.data?.presignedUploadUrl, file, {
    headers: { "Content-Type": file.type },
  });
  return res?.data?.data;
};
export const learningModuleUploadFile = async (
  file: any,
  studyId: string | undefined
) => {
  const fileObj = {
    fileName: file.name,
  };
  const res = await http.post(
    `/admin/study/${studyId}/lms/categories/get_upload_url`,
    fileObj
  );
  await axios.put(res.data.data?.presignedUploadUrl, file, {
    headers: { "Content-Type": file.type },
  });
  return res?.data?.data;
};

export const unitUploadFile = async (
  file: any,
  studyId: string | undefined,
  lessonId: string | undefined
) => {
  const fileObj = {
    fileName: file.name,
  };
  const res = await http.post(
    `/admin/study/${studyId}/lms/lessons/${lessonId}/units/get_upload_url`,
    fileObj
  );
  await axios.put(res.data.data?.presignedUploadUrl, file, {
    headers: { "Content-Type": file.type },
  });
  return res?.data?.data;
};

export const sectionUploadFile = async (
  file: any,
  studyId: any,
  lessonId: any,
  unitId: any
) => {
  const fileObj = {
    fileName: file.name,
  };
  const res = await http.post(
    `/admin/study/${studyId}/lms/lessons/${lessonId}/units/${unitId}/pages/sections/get_upload_url`,
    fileObj
  );
  await axios.put(res.data.data?.presignedUploadUrl, file, {
    headers: { "Content-Type": file.type },
  });
  return res?.data?.data;
};

export const exerciseCollectionUpload = async (
  file: any,
  type: string,
  studyId: string | undefined,
  id: string | undefined
) => {
  const fileObj = {
    fileName: file.name,
  };
  let url: string;
  switch (type) {
    case "exercise_collection":
      url = `/admin/study/${studyId}/exercise-collections/get_upload_url`;
      break;
    case "exercise_category":
      url = `/admin/study/${studyId}/exercise-collections/${id}/get_upload_url`;
      break;
    case "builder":
      url = `/admin/study/${studyId}/exercises/get_upload_url`;
      break;
    default:
      url = `/admin/study/${studyId}/exercise-collections/get_upload_url`;
      break;
  }
  const res = await http.post(url, fileObj);
  await axios.put(res.data.data?.presignedUploadUrl, file, {
    headers: { "Content-Type": file.type },
  });
  return res.data.data;
};

export const builderGetUploadUrl = async (
  file: File,
  studyId: string,
  formId: string
) => {
  const fileObj = { fileName: file.name };

  const res = await http.post<SignedUrlRoot>(
    `/study/${studyId}/forms/${formId}/get_upload_url`,
    fileObj
  );

  await axios.put(res.data.data.presignedUploadUrl, file, {
    headers: { "Content-Type": file.type },
  });

  return res.data.data;
};

export const themeUploadUrl = async (file: File, studyId: string) => {
  const obj = { studyId: studyId, fileName: file.name };
  const res = await http.post<SignedUrlRoot>(`/ui/get_upload_url`, obj);
  await axios.put(res?.data?.data?.presignedUploadUrl, file, {
    headers: { "Content-Type": file.type },
  });

  return res.data.data;
};

export const organisationUploadUrl = async (
  file: File,
  orgnizationId: string
) => {
  const obj = { orgnizationId: orgnizationId, fileName: file.name };
  const res = await http.post(`/organizations/get_upload_url`, obj);
  await axios.put(res?.data?.data?.presignedUploadUrl, file, {
    headers: { "Content-Type": file.type },
  });
  return res?.data?.data;
};

export const responsesGetUploadUrl = async (
  file: File,
  studyId?: string,
  participantId?: string,
  surveySlug?: string,
  surveyAssignmentId?: string
) => {
  const fileObj = { fileName: file.name };
  let params: any = {};

  if (participantId) {
    params["participantId"] = participantId;
  }

  const res = await http.post<SignedUrlRoot>(
    surveySlug
      ? `/survey/${surveySlug}/get_upload_url`
      : surveyAssignmentId
      ? `/study/${studyId}/survey-responses/get_upload_url`
      : `/study/${studyId}/responses/get_upload_url`,
    fileObj,
    { params }
  );

  await axios.put(res.data.data.presignedUploadUrl, file, {
    headers: { "Content-Type": file.type },
  });

  return res.data.data.postUploadImageUrl;
};

export const consentUploadFile = async (
  file: any,
  studyId: string | undefined
) => {
  const fileObj = {
    fileName: file.name,
  };
  const res = await http.post(
    `/admin/study/${studyId}/econsent/get_upload_url`,
    fileObj
  );
  await axios.put(res.data.data?.presignedUploadUrl, file, {
    headers: { "Content-Type": file.type },
  });
  return res?.data?.data;
};
