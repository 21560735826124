import {
  ArticleIcon,
  AssessmentIcon,
  AudioIcon,
  PictureIcon,
  PollingIcon,
  ResourcesIcon,
  TitleIcon,
  VideoIcon,
} from "./Icons";

export const CMSIconMap: any = {
  title: TitleIcon,
  subtitle: TitleIcon,
  video: VideoIcon,
  question: AssessmentIcon,
  text: ArticleIcon,
  picture: PictureIcon,
  resource: ResourcesIcon,
  poll: PollingIcon,
  audio: AudioIcon,
  spaced_learning: AssessmentIcon,
};

export const cards = [
  {
    key: "1",
    name: "Title",
    description: "Title block for headers and sub-headers",
    type: "title",
    value: "",
  },
  {
    key: "2",
    name: "Subtitle",
    description: "Subtitle block to introduce page sections below title",
    type: "subtitle",
    value: "",
  },
  {
    key: "3",
    name: "Video",
    description: "Video block to support for video content",
    type: "video",
    label: "",
    value: "",
    format: "1.778",
  },
  {
    key: "4",
    name: "Assessment",
    description:
      "Question block to add multiple choice or open response question",
    type: "question",
  },
  {
    key: "5",
    name: "Article or Text",
    description: "Text block for body text",
    type: "text",
    value: "",
  },
  {
    key: "6",
    name: "Picture",
    description: "Image block for sharing pictures",
    type: "picture",
    label: "",
    value: "",
    altText: "",
  },
  {
    key: "9",
    name: "Audio",
    description: "Audio block to play audios",
    type: "audio",
    value: "",
    label: "",
  },
  // {
  //   key: "10",
  //   name: "Spaced Learning",
  //   description: "Spaced Learning block to add multiple choice question",
  //   type: "spaced_learning",
  // },
  {
    key: "7",
    name: "Resources",
    description: "Resource block to input images, PDF and links",
    type: "resource",
  },
  {
    key: "8",
    name: "Polling Card",
    description: "Polling card to conduct polls",
    type: "poll",
    value: "",
  },
];

export const selfReflectionCards = [
  {
    key: "1",
    name: "Title",
    description: "Title block for headers and sub-headers",
    type: "title",
    value: "",
  },
  {
    key: "4",
    name: "Assessment",
    description:
      "Question block to add multiple choice or open response question",
    type: "question",
  },
  {
    key: "5",
    name: "Description",
    description: "Text block for body text",
    type: "text",
    value: "",
  },
];

export const LessonQuestionsV2 = [
  {
    type: "multi_select",
    title: "",
    wrongExplanation: "",
    correctExplanation: "",
    educationLessonId: null,
    educationLessonUnitId: null,
    educationLessonPageId: null,
    linkModule: false,
  },
  {
    type: "slider",
    title: "",
    wrongExplanation: "",
    correctExplanation: "",
  },
  {
    type: "timer",
    title: "",
  },
  {
    type: "single_select",
    title: "",
    wrongExplanation: "",
    correctExplanation: "",
    educationLessonId: null,
    educationLessonUnitId: null,
    educationLessonPageId: null,
    linkModule: false,
  },
  {
    type: "text",
    title: "",
  },
];

export const LessonQuestions = [
  {
    type: "multi_select",
    title: "",
    wrongExplanation: "",
    correctExplanation: "",
  },
  {
    type: "slider",
    title: "",
    wrongExplanation: "",
    correctExplanation: "",
  },
  {
    type: "timer",
    title: "",
  },
  {
    type: "single_select",
    title: "",
    wrongExplanation: "",
    correctExplanation: "",
  },
  {
    type: "text",
    title: "",
  },
];
