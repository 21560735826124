import webviewHttp from "./webviewHttp";

export const addBookmark = async (
  body: any,
  studyId: any,
  lessonId: string
) => {
  await webviewHttp.post(
    `/participant/study/${studyId}/lms/lessons/${lessonId}/bookmarks`,
    body
  );
};

export const removeBookmark = async (
  body: any,
  studyId: any,
  lessonId: string
) => {
  await webviewHttp.post(
    `/participant/study/${studyId}/lms/lessons/${lessonId}/bookmarks/remove_bookmark`,
    body
  );
};

export const getBookmarks = async (
  studyId: any,
  unitId: string,
  lessonId: any
) => {
  const id = localStorage.getItem("userId") || "";
  const res = await webviewHttp.get(
    `/participant/study/${studyId}/lms/lessons/${lessonId}/bookmarks?participantId=${id}&educationLessonUnitId=${unitId}`
  );
  return res;
};
