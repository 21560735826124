import { Box, Typography } from "@mui/material";
import { PoweredByCircleLogo } from "../assets/Sidebar";

const VersionWrapper = () => (
  <Box>
    <Box
      sx={{
        mt: "auto",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        gap: "8px",
      }}
    >
      <PoweredByCircleLogo />
      <Typography variant="body1" fontWeight={"medium"} color="gray">
        Powered by Mahalo
      </Typography>
    </Box>
    <Typography
      variant="body1"
      fontWeight={"medium"}
      color="gray"
      fontSize={12}
      sx={{ textAlign: "center" }}
    >
      Version 5.0.0 (11/12/2024)
    </Typography>
  </Box>
);

export default VersionWrapper;
