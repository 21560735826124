import React from "react";
import { Autocomplete, TextField } from "@mui/material";

interface Option {
  value: string;
  label: string;
}

type Props = {
  value: string;
  name: string;
  setFieldValue: Function;
  countriesList: Option[];
};

const CountrySelect: React.FC<Props> = ({
  value,
  name,
  setFieldValue,
  countriesList,
}) => {
  return (
    <Autocomplete
      value={countriesList.find((option) => option.value === value) || null}
      onChange={(_: any, newValue) => {
        setFieldValue(name, newValue ? newValue.value : "");
      }}
      disablePortal
      ListboxProps={{
        style: {
          maxHeight: "300px",
          overflowY: "auto",
        },
      }}
      options={countriesList}
      getOptionLabel={(option: Option) => option.label}
      renderInput={(params) => <TextField {...params} placeholder="Country" />}
      isOptionEqualToValue={(option, value) => option.value === value.value}
    />
  );
};

export default React.memo(CountrySelect);
